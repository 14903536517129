@import url('https://fonts.googleapis.com/css2?family=Questrial&family=Nunito+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --aegis-blue: #1A7DB6;
  --heading-font: 'Questrial', sans-serif;
  --body-font: 'Nunito Sans', sans-serif;
}

/* High contrast theme */
[data-contrast="high"] {
  --text-primary: #000000 !important;
  --text-secondary: #000000 !important;
  --bg-primary: #ffffff !important;
  --bg-secondary: #ffffff !important;
  --border-color: #000000 !important;
  --link-color: #000000 !important;
  --button-bg: #000000 !important;
  --button-text: #ffffff !important;
}

/* Inverted theme */
[data-contrast="inverted"] {
  --text-primary: #ffffff !important;
  --text-secondary: #cccccc !important;
  --bg-primary: #000000 !important;
  --bg-secondary: #1a1a1a !important;
  --border-color: #333333 !important;
  --link-color: #ffffff !important;
  --button-bg: #ffffff !important;
  --button-text: #000000 !important;
}

/* Only apply these styles when a contrast option is selected */
[data-contrast="high"], [data-contrast="inverted"] {
  .text-gray-600 {
    color: var(--text-secondary) !important;
  }

  .text-gray-800, .text-gray-900 {
    color: var(--text-primary) !important;
  }

  .bg-white {
    background-color: var(--bg-primary) !important;
  }

  .bg-gray-50 {
    background-color: var(--bg-secondary) !important;
  }

  .border {
    border-color: var(--border-color) !important;
  }

  button:not([class*="bg-"]) {
    background-color: var(--button-bg);
    color: var(--button-text);
  }

  a:not([class*="text-"]) {
    color: var(--link-color);
  }
}

/* Preserve brand colors always */
.text-aegis-blue {
  color: var(--aegis-blue) !important;
}

.bg-aegis-blue {
  background-color: var(--aegis-blue) !important;
}

/* Original styles */
.body-text {
  font-size: 20px;
  line-height: 1.67;
  text-align: center;
}

.heading-text {
  font-size: 36px;
  line-height: 1.25;
  font-weight: 400;
}

@media (max-width: 768px) {
  .body-text {
    font-size: 16px;
  }
  
  .heading-text {
    font-size: 28px;
  }
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.bg-gradient-overlay {
  background: linear-gradient(to top, rgba(26, 125, 182, 0.8), rgba(26, 125, 182, 0) 100%);
}